/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    flex: 1,
  },
  link: {
    color: "#000000",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.25em 0em 1em 0em",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.688rem",
    color: "#000000",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0.25em 0em 1em 0em",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.688rem",
    color: "#000000",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1.5rem",
  },
  iframe: {
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: "4px",
    [theme.breakpoints.down("xs")]: {
      height: "15rem",
    },
    [theme.breakpoints.only("sm")]: {
      height: "22rem",
    },
    [theme.breakpoints.only("md")]: {
      height: "17rem",
    },
    [theme.breakpoints.only("lg")]: {
      height: "18rem",
    },
    [theme.breakpoints.only("xl")]: {
      height: "18rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "24rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 920)]: {
      height: "24rem",
    },
  },
}));

const ContactAddress = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.flex}>
        {/* Email */}
        <Link
          href="mailto:mail@psolifestyle.com"
          rel="noopener"
          aria-label="email"
          title="email"
          className={classes.link}
        >
          <div className={classes.container}>
            <Typography
              variant="body1"
              component="span"
              className={classes.heading}
            >
              Email:
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.text}
            >
              mail@psolifestyle.com
            </Typography>
          </div>
        </Link>

        {/* Phone */}
        <Link
          href="tel:+919632903999"
          rel="noopener"
          aria-label="phone"
          title="phone"
          className={classes.link}
        >
          <div className={classes.container}>
            <Typography
              variant="body1"
              component="span"
              className={classes.heading}
            >
              Phone:
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.text}
            >
              +91 9632903999
            </Typography>
          </div>
        </Link>

        {/* Address */}
        <Link
          href="//g.page/psolifestyle?share"
          rel="noopener"
          aria-label="address"
          title="address"
          className={classes.link}
        >
          <div className={classes.container}>
            <Typography
              variant="body1"
              component="span"
              className={classes.heading}
            >
              Address:
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.text}
            >
              <span>
                Terrace, 1090I, 4<sup>th</sup> Floor, 18<sup>th</sup> Cross
                Road, 3<sup>rd</sup> Sector, HSR Layout, Bengaluru, Karnataka
                560 102
              </span>
            </Typography>
          </div>
        </Link>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.93790186719!2d77.63856951526958!3d12.91171271968193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1443052102f5%3A0xfaea4f80cbfb4f59!2sParadigm%20Shift%20Organica%20(PSO)!5e0!3m2!1sen!2sin!4v1636455686432!5m2!1sen!2sin"
          width={500}
          height={350}
          className={classes.iframe}
          frameBorder="0"
          aria-hidden="false"
          tabIndex="0"
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactAddress;
