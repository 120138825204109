/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import {
  Paper,
  TextField,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// import AppConfig from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  contactPaper: {
    padding: "2rem 1.5rem 2rem 1.5rem",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    width: "100%",
    color: "#586472",
    fontWeight: "600",
    fontSize: "1.75rem",
    lineHeight: "2.5rem",
    margin: "0rem 0rem 1rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  grey: {
    color: "#B8BCCA",
  },
  link: {
    color: "#0048D9",
    textDecoration: "none",
  },
  contactForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    width: "100%",
  },
  textField: {
    margin: "0rem 0rem 2rem 0rem",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  msgField: {
    margin: "0rem 0rem 2rem 0rem",
  },
  autoField: {
    margin: "0rem 0.5rem 2rem 0rem",
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    alignItems: "center",
  },
  primaryButton: {
    margin: "1.5rem 0rem 0rem 0rem",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    background: "linear-gradient(180deg, #75E88A 0%, #2CB646 100%)",
    "&:hover": {
      background: "linear-gradient(180deg, #75E88A 0%, #2CB646 100%)",
      color: "#FFFFFF",
    },
    width: "100%",
  },
  fieldGap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactForm = (props) => {
  const classes = useStyles();

  const [atc, setAtc] = React.useState(false);
  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);

  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const submitForm = () => {
    const reqURL = encodeURI(
      // AppConfig.apiUrlPrefix +
      "/api/contact?firstname=" +
        firstname +
        "&lastname=" +
        lastname +
        "&email=" +
        email +
        "&phone=" +
        phone +
        "&message=" +
        message +
        "&atc=" +
        atc +
        "&source=" +
        (props.source ? props.source : "unknown")
    );
    setLoadingSnackbar(true);
    fetch(reqURL)
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            setLoadingSnackbar(false);
            setSuccessSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(data);
            /* TODO: Send Slack message with lead */
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
            if (typeof submitCallback === "function") submitCallback(error);
            /* TODO: Send Slack message with error */
          });
      })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
        if (typeof submitCallback === "function") submitCallback(error);
        /* TODO: Send Slack message with error */
      });
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Sending information...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Thanks, {firstname}! We&apos;ll get in touch shortly.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone wrong!
        </Alert>
      </Snackbar>
      <Paper elevation={3} className={classes.contactPaper}>
        <form id="cf" className={classes.contactForm} method="GET">
          <div className={classes.fieldGap}>
            <TextField
              id="name"
              name="name"
              label="First Name"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
            <label htmlFor="name"></label>
            <TextField
              id="name"
              name="name"
              label="Last Name"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
            <label htmlFor="name"></label>
          </div>
          <div className={classes.fieldGap}>
            <TextField
              id="phone"
              name="phone"
              label="Phone"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <label htmlFor="phone"></label>
            <TextField
              id="email"
              name="email"
              label="Email"
              required={true}
              type="email"
              className={classes.textField}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <label htmlFor="email"></label>
          </div>
          <TextField
            id="message"
            name="message"
            label="Message"
            required={true}
            type="text"
            multiline={true}
            rows={3}
            className={classes.msgField}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
          <label htmlFor="message"></label>
          <input
            id="source"
            type="hidden"
            name="source"
            value={props.source}
            onChange={(event) => {
              setSource(event.target.value);
            }}
          />
          <div className={classes.checkboxRow}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                  checked={atc}
                  onChange={(event) => {
                    setAtc(!atc);
                  }}
                  color="primary"
                  value={atc}
                  name="agree"
                />
              }
              label={
                <span>
                  <span className={classes.grey}>
                    I have read and agree with the{" "}
                  </span>
                  <Link
                    href="/terms"
                    aria-label="Terms of Service"
                    title="Terms of Service"
                    rel="noopener"
                  >
                    Terms of Service{" "}
                  </Link>
                  and{" "}
                  <Link
                    href="/privacy"
                    aria-label="Privacy Policy"
                    title="Privacy Policy"
                    rel="noopener"
                  >
                    Privacy Policy
                  </Link>
                </span>
              }
            />
          </div>
          <div className={classes.btn}>
            <Button
              variant="contained"
              title="Submit"
              onClick={submitForm}
              rel="noopener"
              className={classes.primaryButton}
            >
              Get a call
            </Button>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
};

export default ContactForm;
