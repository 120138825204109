/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";
import ContactForm from "./sub-components/ContactForm";
import ContactAddress from "./sub-components/ContactAddress";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "1rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0rem 3rem 0rem",
    gap: "2rem",
  },
  heading: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "3.1875rem",
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  headingText: {
    width: "100%",
    margin: "2rem 0rem 2rem 0rem",
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <span id="contact"></span>
      <div className={classes.headingText}>
        <Typography
          variant="body2"
          component="span"
          className={classes.heading}
        >
          Contact Us
        </Typography>
      </div>
      <div className={classes.sectionContainer}>
        <ContactForm />
        <ContactAddress />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
